.PopupContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    border-radius: 2vh;
    background-color: #000000b3;
    border: solid 2px #BABABAb3;
    color: white;
    margin-top: 40vh;
    height: 100%;
    width: 300px;
    padding: 2vh;
    z-index: 30;
    /* animation-name: fadeInLeft; */
    /* animation-duration: 1s; */
}

@keyframes fadeInLeft {
    0% {
        transform: translate(600px);
        opacity: 0%;
    }
    100% {
        transform: translate(0px);
        opacity: 100%;
    }
}

@keyframes fadeOutLeftFadeInLeft {
    0%   {
        transform: translate();
    }
    50%  {
        background-color: blue;
    }
    51% {

    }
    100% {
        background-color: green;
    }
  }

.Pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    margin: 24px 0px 8px;
    /* background-color: #f00; */
}

.PaginationButton {
    width:18%;
    height: 4vh;
    border:none;
    border-radius: .8vh;
    color:#fff;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}