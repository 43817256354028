.HenkelContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:90%;
    padding: 18px 12px;
    margin: 8px;
    /* background-color: #f00; */
    border-radius: 3vw;

}