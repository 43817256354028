.OverlayContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SquareCutout {
    content:'';
    display: flex;
    position: absolute;
    border-radius:4%;
    width:30vh; height:30vh;
    margin-bottom: 20vh;
    box-shadow: 0px 0px 0px 2000px #0005;
}

.SquareIndicator {
    display: flex;
    position: absolute;
    border-radius:4%;
    width:30vh; height:30vh;
    margin-bottom: 20vh;
    z-index: 9999;
    background-color: transparent;
    border: solid 3px red;
    clip-path: polygon(80% 0, 100% 0, 100% 20%, 84% 18%, 84% 85%, 80% 100%, 100% 100%, 100% 80%, 0 80%, 0 100%, 20% 100%, 20% 0, 0 0, 0 20%, 19% 16%, 80% 24%);
}