/* Font imports */
/* Import Inter font family */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Thin.ttf') format('truetype'); /* Thin font */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraLight.ttf') format('truetype'); /* Extra Light font */
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Light.ttf') format('truetype'); /* Light font */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Regular.ttf') format('truetype'); /* Regular font */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Medium.ttf') format('truetype'); /* Medium font */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-SemiBold.ttf') format('truetype'); /* Semi-Bold font */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.ttf') format('truetype'); /* Bold font */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraBold.ttf') format('truetype'); /* Extra Bold font */
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Black.ttf') format('truetype'); /* Black font */
  font-weight: 900;
  font-style: normal;
}

/* Apply Inter font family to elements */
body {
  font-family: 'Inter', sans-serif;
}

/* Example of using different font weights */
h1 {
  font-weight: 100; /* Thin */
  font-family: 'Inter', sans-serif;
}

h2 {
  font-weight: 500; /* Medium */
  font-family: 'Inter', sans-serif;
}

h3 {
  font-weight: 900; /* Black */
  font-family: 'Inter', sans-serif;
}

p {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

label {
  font-family: 'Inter', sans-serif;
}


.App {
  text-align: center;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: visible;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Targets the QR reader's SVG, just a workaround */

#root > div > div:nth-child(2) > svg {
  display: none;
}

#root > div > div > svg {
  display: none;
}

.HenkelButton {
  display: flex; 
  width: 64px;
  height: 64px;
  position: absolute;
  border: solid 2px #BABABAB8;
  background-color: #00000070;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
}
