.LoaderContainer {
    height: 100dvh;
    width: 100vw;
    background-color: #000000fc;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LoaderImage {
    display: flex !important;
    width: 150px;
    height: 150px;
}